export const API_CONFIG = {
    // Définition dynamique de l'URL de base selon l'environnement
    BASE_URL: process.env.NODE_ENV === 'development'
        ? 'http://localhost:5000'
        : 'https://sumfy.fr/api',

    // Vos endpoints restent les mêmes
    ENDPOINTS: {
        LOGIN: '/login',
        REGISTER: '/register',
        PROJECTS: '/projects'
    }
};