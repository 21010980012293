// src/services/auth.service.js
import { API_CONFIG } from '../config/constants';

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No authentication token found');
    }
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

export const authService = {
    login: async ({ email, password }) => {
        try {

            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('username');

            const response = await fetch(`${API_CONFIG.BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Ajoutez cette ligne
                body: JSON.stringify({ email, password })
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.error || 'Échec de la connexion');
            }

            const data = await response.json();
            if (data.token) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('userId', data.user_id);
                if (data.username) {
                    localStorage.setItem('username', data.username);
                }
            }
            return data;
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    },

    // Le reste du service reste inchangé
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('username');
        window.location.href = '/login';
    },

    isAuthenticated: () => {
        return !!localStorage.getItem('token');
    },

    getCurrentUser: async () => {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/user/profile`, {
                method: 'GET',
                headers: getAuthHeader(),
                credentials: 'include'
            });

            if (!response.ok) {
                if (response.status === 401) {
                    authService.logout();
                    throw new Error('Session expirée');
                }
                const error = await response.json();
                throw new Error(error.error || 'Échec de la récupération du profil');
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching user profile:', error);
            throw error;
        }
    },

    changePassword: async (passwordData) => {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/user/change-password`, {
                method: 'POST',
                headers: getAuthHeader(),
                credentials: 'include',
                body: JSON.stringify({
                    current_password: passwordData.currentPassword,
                    new_password: passwordData.newPassword
                })
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.error || 'Échec du changement de mot de passe');
            }

            return await response.json();
        } catch (error) {
            console.error('Error changing password:', error);
            throw error;
        }
    }
};