import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, Select, MenuItem, FormControl, InputLabel, Slider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { API_CONFIG } from '../../config/constants';
import RefreshIcon from '@mui/icons-material/Refresh';

function TranscriptViewer({ transcript, currentTime, onSeek, selectedBlock, onBlockSelect, onReloadAudio, onTranscriptUpdate, onSave, projectId, onReloadWaveform }) {
    const blockRefs = useRef({});
    const [editingBlock, setEditingBlock] = useState(null);
    const [editingStartTime, setEditingStartTime] = useState('');
    const [editingText, setEditingText] = useState('');
    const [editingEmotion, setEditingEmotion] = useState('positivity');
    const [editingPace, setEditingPace] = useState('normal');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [textChanged, setTextChanged] = useState(false);
    const [timeChanged, setTimeChanged] = useState(false);
    const [speakerMap, setSpeakerMap] = useState({});
    const [isAddingNewSegment, setIsAddingNewSegment] = useState(false);
    const [newSegmentText, setNewSegmentText] = useState('');
    const [newSegmentStartTime, setNewSegmentStartTime] = useState('');
    const [newSegmentSpeaker, setNewSegmentSpeaker] = useState('');
    const scrollContainerRef = useRef(null);
    const [selectedReference, setSelectedReference] = useState('');
    const [editingGain, setEditingGain] = useState(1);
    const [isRedubbing, setIsRedubbing] = useState(null); // Track which segment is being redubbed
    const [editingReference, setEditingReference] = useState(null); // {speaker: "", segment_file: ""}

    useEffect(() => {
        // Initialize the speaker map
        const newSpeakerMap = {};
        transcript.forEach(item => {
            if (!newSpeakerMap[item.speaker]) {
                newSpeakerMap[item.speaker] = `SPEAKER_${Object.keys(newSpeakerMap).length}`;
            }
        });
        setSpeakerMap(newSpeakerMap);

        // Set default speaker for new segments
        if (Object.keys(newSpeakerMap).length > 0 && !newSegmentSpeaker) {
            setNewSegmentSpeaker(Object.keys(newSpeakerMap)[0]);
        }
    }, [transcript]);  // Dépendance ajoutée pour que l'effet se déclenche quand transcript change


    const PRIMARY_COLOR = '#6366f1';
    const SECONDARY_COLOR = '#4f46e5';

    useEffect(() => {
        // Initialize the speaker map
        const newSpeakerMap = {};
        transcript.forEach(item => {
            if (!newSpeakerMap[item.speaker]) {
                newSpeakerMap[item.speaker] = `SPEAKER_${Object.keys(newSpeakerMap).length}`;
            }
        });
        setSpeakerMap(newSpeakerMap);
    }, []);



    useEffect(() => {
        if (selectedBlock && blockRefs.current[selectedBlock.start]) {
            blockRefs.current[selectedBlock.start].scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }, [selectedBlock]);

    const handleBlockClick = (item) => {
        if (editingBlock === null) {
            onBlockSelect(item);
        }
    };

    // Nouvelle fonction handleRedub
    const handleRedub = async (e, item) => {
        e.stopPropagation();
        try {
            setIsRedubbing(item.segment_file);
            const response = await fetch(`${API_CONFIG.BASE_URL}/redub_segment/${projectId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    segment_data: item
                })
            });

            if (!response.ok) {
                throw new Error('Failed to redub segment');
            }

            const result = await response.json();
            onTranscriptUpdate(result.updated_transcript);

            if (onReloadAudio) {
                onReloadAudio(item.speaker, result.updated_segment);
            }
            if (onReloadWaveform) {
                onReloadWaveform(item.speaker, result.updated_segment);
            }

            await onSave(result.updated_transcript);
        } catch (error) {
            console.error('Error redubbing segment:', error);
        } finally {
            setIsRedubbing(null);
        }
    };


    const handleAddNewSegment = () => {
        setIsAddingNewSegment(true);
        setNewSegmentStartTime(currentTime.toFixed(2));

        // Faire défiler vers le haut du conteneur
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const handleNewSegmentSubmit = async () => {
        if (newSegmentText && newSegmentStartTime && newSegmentSpeaker) {
            setIsSubmitting(true);
            try {
                const formData = new FormData();
                formData.append('text', newSegmentText);
                formData.append('start_time', newSegmentStartTime);
                formData.append('emotion', editingEmotion);
                formData.append('pace', editingPace);
                formData.append('speaker', newSegmentSpeaker);
                formData.append('project_id', projectId); // Make sure projectId is available
                formData.append('reference_segment', selectedReference); // Ajouter la référence

                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                console.log('Submitting new segment:', {
                    text: newSegmentText,
                    start_time: newSegmentStartTime,
                    emotion: editingEmotion,
                    pace: editingPace,
                    speaker: newSegmentSpeaker,
                    project_id: projectId
                });

                const response = await fetch(`${API_CONFIG.BASE_URL}/add_new_segment`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData,
                });


                if (response.ok) {
                    const result = await response.json();
                    onTranscriptUpdate(result.updated_transcript);

                    setIsAddingNewSegment(false);
                    setNewSegmentText('');
                    setNewSegmentStartTime('');
                    setNewSegmentSpeaker('');
                    setEditingEmotion('normal');
                    setEditingPace('normal');

                    await onSave(result.updated_transcript);

                } else {
                    const errorData = await response.json();
                    console.error('Error from API:', errorData);
                    throw new Error(errorData.error || 'An error occurred while adding the new segment');
                }
            } catch (error) {
                console.error('Error submitting new segment:', error);
                // Here you might want to show an error message to the user
            }
            setIsSubmitting(false);
        }
    };

    const handleNewSegmentCancel = () => {
        setIsAddingNewSegment(false);
        setNewSegmentText('');
        setNewSegmentStartTime('');
    };

    const handleEditClick = (e, index, item) => {
        e.stopPropagation();
        setEditingBlock(index);
        setEditingStartTime(item.start.toFixed(3));
        setEditingText(item.text);
        setEditingEmotion(item.emotion || 'normal');
        setEditingPace(item.speed || 'normal');
        setEditingGain(item.audio_gain || 1);
        setEditingReference({ speaker: item.speaker, segment_file: item.segment_file }); // La référence actuelle par défaut
        setTextChanged(false);
        setTimeChanged(false);
    };



    const handleDeleteClick = async (e, index) => {
        e.stopPropagation();
        const itemToDelete = transcript[index];
        const updatedTranscript = [...transcript];
        updatedTranscript.splice(index, 1);
        onTranscriptUpdate(updatedTranscript);

        try {
            await fetch(`${API_CONFIG.BASE_URL}/delete_segment/${projectId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ segments: [itemToDelete] })
            });
        } catch (error) {
            console.error('Error deleting segment:', error);
        }

        await onSave(updatedTranscript);
    };

    const getColor = (speaker) => {
        const colors = ["#4B90F5", "#45C75E", "#F5A623", "#FF6B6B"];
        const mappedSpeaker = speakerMap[speaker] || speaker;
        const speakerIndex = parseInt(mappedSpeaker.split('_')[1], 10);
        return colors[speakerIndex % colors.length];
    };

    const handleStartTimeChange = (value, index) => {
        const newStartTime = parseFloat(value);
        if (!isNaN(newStartTime)) {
            setTimeChanged(true);
            setEditingStartTime(value);
        }
    };



    const handleTextChange = (value) => {
        setEditingText(value);
        setTextChanged(true);
    };

    const handleSubmit = async (index) => {
        setIsSubmitting(true);
        try {
            const item = transcript[index];
            const formData = new FormData();
            formData.append('text', editingText);

            // On garde les infos du segment original qu'on modifie
            formData.append('original_segment_file', item.segment_file);
            formData.append('original_speaker', item.speaker);

            // On ajoute séparément les infos du segment de référence pour le TTS
            formData.append('reference_segment_file', editingReference?.segment_file || item.segment_file);
            formData.append('reference_speaker', editingReference?.speaker || item.speaker);

            formData.append('emotion', editingEmotion);
            formData.append('pace', editingPace);

            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${API_CONFIG.BASE_URL}/edit_segment/${projectId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();

                // Mettre à jour le transcript avec les nouvelles données
                await onTranscriptUpdate(result.updatedTranscript);
                await onSave(result.updatedTranscript);

                setEditingBlock(null);
                setTextChanged(false);
                setTimeChanged(false);

                // Recharger la waveform si nécessaire
                if (onReloadWaveform) {
                    const updatedSegment = result.updated_segment;
                    onReloadWaveform(updatedSegment.speaker, updatedSegment);
                }

                // Recharger l'audio si nécessaire
                if (onReloadAudio) {
                    const updatedSegment = result.updated_segment;
                    onReloadAudio(updatedSegment.speaker, updatedSegment);
                }

            } else {
                const errorData = await response.json();
                console.error('Error from API:', errorData);
                throw new Error(errorData.error || 'An error occurred while updating the segment');
            }
        } catch (error) {
            console.error('Error submitting changes:', error);
            // Ici vous pourriez ajouter une notification d'erreur pour l'utilisateur
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCancel = () => {
        setEditingBlock(null);
        setTextChanged(false);
        setTimeChanged(false);
    };



    if (!transcript) return null;
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            <Box
                ref={scrollContainerRef}

                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    p: 2,
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#555',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#333',
                        borderRadius: '4px',
                    },
                }}
            >
                <>
                    {isAddingNewSegment && (
                        <Box
                            sx={{
                                mb: 1,
                                bgcolor: 'rgba(32, 33, 36, 0.9)',
                                borderRadius: '4px',
                                p: 1.5,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                maxWidth: '100%',
                                width: '100%'
                            }}
                        >
                            <TextField
                                value={newSegmentText}
                                onChange={(e) => setNewSegmentText(e.target.value)}
                                variant="outlined"
                                multiline
                                size="small"
                                placeholder="Enter new segment text"
                                sx={{
                                    width: "99%",
                                    '& .MuiOutlinedInput-root': {
                                        color: 'white',
                                        fontSize: '0.875rem',
                                        '& fieldset': {
                                            borderColor: 'rgba(255,255,255,0.23)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: PRIMARY_COLOR,
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: PRIMARY_COLOR,
                                        },
                                    },
                                    mb: 2
                                }}
                            />
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                                <FormControl variant="outlined" size="small" sx={{ minWidth: 120, flexGrow: 1 }}>
                                    <InputLabel sx={{ color: 'rgba(255,255,255,0.7)' }}>Speaker</InputLabel>
                                    <Select
                                        value={newSegmentSpeaker}
                                        onChange={(e) => setNewSegmentSpeaker(e.target.value)}
                                        label="Speaker"
                                        sx={{
                                            color: 'white',
                                            fontSize: '0.8rem',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(255,255,255,0.23)',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: PRIMARY_COLOR,
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: PRIMARY_COLOR,
                                            },
                                        }}
                                    >
                                        {Object.keys(speakerMap).map((speaker) => (
                                            <MenuItem key={speaker} value={speaker}>{speaker}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {/* Nouveau sélecteur de référence */}
                                {newSegmentSpeaker && (
                                    <FormControl variant="outlined" size="small" sx={{ minWidth: 120, flexGrow: 1 }}>
                                        <InputLabel sx={{ color: 'rgba(255,255,255,0.7)' }}>Reference Audio</InputLabel>
                                        <Select
                                            value={selectedReference || ''} // Utiliser une chaîne vide si null
                                            onChange={(e) => setSelectedReference(e.target.value)}
                                            label="Reference Audio"
                                            sx={{
                                                color: 'white',
                                                fontSize: '0.8rem',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(255,255,255,0.23)',
                                                },
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {transcript
                                                .filter(seg => seg.speaker === newSegmentSpeaker)
                                                .map((segment, idx) => (
                                                    <MenuItem key={segment.segment_file} value={segment.segment_file}>
                                                        {`Segment ${idx + 1} (${formatTimeDisplaySimple(segment.start)} - ${formatTimeDisplaySimple(segment.end)})`}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                )}
                                <FormControl variant="outlined" size="small" sx={{ minWidth: 120, flexGrow: 1 }}>
                                    <InputLabel sx={{ color: 'rgba(255,255,255,0.7)' }}>Emotion</InputLabel>
                                    <Select
                                        value={editingEmotion}
                                        onChange={(e) => setEditingEmotion(e.target.value)}
                                        label="Emotion"
                                        sx={{
                                            color: 'white',
                                            fontSize: '0.8rem',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(255,255,255,0.23)',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: PRIMARY_COLOR,
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: PRIMARY_COLOR,
                                            },
                                        }}
                                    >
                                        {['normal', 'anger', 'curiosity', 'positivity', 'surprise', 'sadness'].map((emotion) => (
                                            <MenuItem key={emotion} value={emotion}>{emotion}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" size="small" sx={{ minWidth: 120, flexGrow: 1 }}>
                                    <InputLabel sx={{ color: 'rgba(255,255,255,0.7)' }}>Speed</InputLabel>
                                    <Select
                                        value={editingPace}
                                        onChange={(e) => setEditingPace(e.target.value)}
                                        label="Speed"
                                        sx={{
                                            color: 'white',
                                            fontSize: '0.8rem',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(255,255,255,0.23)',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: PRIMARY_COLOR,
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: PRIMARY_COLOR,
                                            },
                                        }}
                                    >
                                        {['normal', 'fast', 'slow'].map((speed) => (
                                            <MenuItem key={speed} value={speed}>{speed}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
                                <Button
                                    onClick={handleNewSegmentCancel}
                                    sx={{
                                        color: 'rgba(255,255,255,0.7)',
                                        '&:hover': {
                                            color: PRIMARY_COLOR,
                                        },
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleNewSegmentSubmit}
                                    variant="contained"
                                    disabled={isSubmitting || !selectedReference}
                                    sx={{
                                        bgcolor: PRIMARY_COLOR,
                                        '&:hover': {
                                            bgcolor: SECONDARY_COLOR,
                                        },
                                    }}
                                >
                                    {isSubmitting ? 'Adding...' : 'Add Segment'}
                                </Button>
                            </Box>
                        </Box>
                    )}
                </>
                {transcript.map((item, index) => (
                    <Box
                        key={index}
                        ref={el => blockRefs.current[item.start] = el}
                        onClick={() => handleBlockClick(item)}
                        sx={{
                            mb: 1,
                            bgcolor: selectedBlock === item ? `${PRIMARY_COLOR}1A` : 'rgba(32, 33, 36, 0.9)',
                            borderRadius: '4px',
                            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                            transition: 'all 0.2s ease',
                            cursor: 'pointer',
                            '&:hover': {
                                bgcolor: `${PRIMARY_COLOR}0D`,
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
                            },
                            position: 'relative',
                            overflow: 'hidden',
                            borderLeft: `6px solid ${getColor(item.speaker)}`,
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 1.5,
                        }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                                    <Typography variant="caption" sx={{
                                        color: 'rgba(255,255,255,0.7)',
                                        fontWeight: 'bold',
                                        mr: 1,
                                    }}>
                                        {formatTimeDisplaySimple(item.start)} - {formatTimeDisplaySimple(item.end)}
                                    </Typography>
                                    <Box
                                        sx={{
                                            bgcolor: getColor(item.speaker),
                                            color: 'white',
                                            borderRadius: '2px',
                                            px: 0.5,
                                            py: 0.25,
                                            fontSize: '0.65rem',
                                            fontWeight: 'bold',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {item.speaker}
                                    </Box>
                                </Box>
                                {editingBlock === index ? (
                                    <TextField
                                        value={editingText}
                                        onChange={(e) => handleTextChange(e.target.value)}
                                        variant="outlined"
                                        multiline
                                        size="small"
                                        sx={{
                                            width: "99%"
                                            ,
                                            '& .MuiOutlinedInput-root': {
                                                color: 'white',
                                                fontSize: '0.875rem',
                                                '& fieldset': {
                                                    borderColor: 'rgba(255,255,255,0.23)',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: PRIMARY_COLOR,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: PRIMARY_COLOR,
                                                },
                                            },
                                        }}
                                    />
                                ) : (
                                    <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)', fontSize: '0.875rem' }}>
                                        {item.text}
                                    </Typography>
                                )}
                                {item.translated_text && (
                                    <Typography variant="caption" sx={{ mt: 0.5, display: 'block', color: 'rgba(255,255,255,0.6)', fontSize: '0.75rem' }}>
                                        {item.translated_text}
                                    </Typography>
                                )}
                            </Box>

                            <Box sx={{ ml: 1, display: 'flex', flexDirection: 'column' }}>
                                {editingBlock !== index ? (
                                    <>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleRedub(e, item)}
                                            disabled={isRedubbing === item.segment_file}
                                            sx={{
                                                color: 'rgba(255,255,255,0.7)',
                                                '&:hover': { color: PRIMARY_COLOR },
                                                '&.Mui-disabled': {
                                                    color: 'rgba(255,255,255,0.3)'
                                                }
                                            }}
                                        >
                                            <RefreshIcon
                                                fontSize="small"
                                                sx={{
                                                    animation: isRedubbing === item.segment_file ? 'spin 1s linear infinite' : 'none',
                                                    '@keyframes spin': {
                                                        '0%': { transform: 'rotate(0deg)' },
                                                        '100%': { transform: 'rotate(360deg)' }
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleEditClick(e, index, item)}
                                            sx={{ color: 'rgba(255,255,255,0.7)', '&:hover': { color: PRIMARY_COLOR } }}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleDeleteClick(e, index)}
                                            sx={{ color: 'rgba(255,255,255,0.7)', '&:hover': { color: '#ff4d4f' } }}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleSubmit(index)}
                                            disabled={isSubmitting}
                                            size="small"
                                            sx={{
                                                mb: 0.5,
                                                minWidth: 0,
                                                px: 1,
                                                py: 0.5,
                                                bgcolor: PRIMARY_COLOR,
                                                '&:hover': {
                                                    bgcolor: SECONDARY_COLOR,
                                                },
                                            }}
                                        >
                                            {isSubmitting ? '...' : '✓'}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={handleCancel}
                                            disabled={isSubmitting}
                                            size="small"
                                            sx={{
                                                minWidth: 0,
                                                px: 1,
                                                py: 0.5,
                                                borderColor: 'rgba(255,255,255,0.23)',
                                                color: 'rgba(255,255,255,0.7)',
                                                '&:hover': {
                                                    borderColor: PRIMARY_COLOR,
                                                    color: PRIMARY_COLOR,
                                                },
                                            }}
                                        >
                                            ✕
                                        </Button>
                                    </>
                                )}
                            </Box>
                        </Box>
                        {editingBlock === index && (
                            <Box sx={{ px: 1.5, pb: 1.5, display: 'flex', flexDirection: 'column', gap: 2 }}>
                                {/* Première ligne pour les références */}
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    <FormControl variant="outlined" size="small" sx={{ minWidth: 120, flexGrow: 1 }}>
                                        <InputLabel sx={{ color: 'rgba(255,255,255,0.7)' }}>Speaker</InputLabel>
                                        <Select
                                            value={editingReference?.speaker || item.speaker}
                                            onChange={(e) => setEditingReference(prev => ({
                                                ...prev,
                                                speaker: e.target.value,
                                                segment_file: ''  // Reset le segment quand on change de speaker
                                            }))}
                                            label="Speaker"
                                            sx={{
                                                color: 'white',
                                                fontSize: '0.8rem',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(255,255,255,0.23)',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: PRIMARY_COLOR,
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: PRIMARY_COLOR,
                                                },
                                            }}
                                        >
                                            {Object.keys(speakerMap).map((speaker) => (
                                                <MenuItem key={speaker} value={speaker}>{speaker}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="outlined" size="small" sx={{ minWidth: 120, flexGrow: 1 }}>
                                        <InputLabel sx={{ color: 'rgba(255,255,255,0.7)' }}>Reference Segment</InputLabel>
                                        <Select
                                            value={editingReference?.segment_file || item.segment_file}
                                            onChange={(e) => setEditingReference(prev => ({
                                                ...prev,
                                                segment_file: e.target.value
                                            }))}
                                            label="Reference Segment"
                                            sx={{
                                                color: 'white',
                                                fontSize: '0.8rem',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(255,255,255,0.23)',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: PRIMARY_COLOR,
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: PRIMARY_COLOR,
                                                },
                                            }}
                                        >
                                            {transcript
                                                .filter(seg => seg.speaker === (editingReference?.speaker || item.speaker))
                                                .map((segment, idx) => (
                                                    <MenuItem
                                                        key={segment.segment_file}
                                                        value={segment.segment_file}
                                                    >
                                                        {segment.segment_file === item.segment_file ?
                                                            "Current Segment" :
                                                            `Segment ${idx + 1} (${formatTimeDisplaySimple(segment.start)} - ${formatTimeDisplaySimple(segment.end)})`
                                                        }
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                {/* Deuxième ligne pour emotion et speed */}
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                                        <InputLabel sx={{ color: 'rgba(255,255,255,0.7)' }}>Emotion</InputLabel>
                                        <Select
                                            value={editingEmotion}
                                            onChange={(e) => setEditingEmotion(e.target.value)}
                                            label="Emotion"
                                            sx={{
                                                color: 'white',
                                                fontSize: '0.8rem',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(255,255,255,0.23)',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: PRIMARY_COLOR,
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: PRIMARY_COLOR,
                                                },
                                            }}
                                        >
                                            <MenuItem value="normal">Normal</MenuItem>
                                            <MenuItem value="anger">Anger</MenuItem>
                                            <MenuItem value="curiosity">Curiosity</MenuItem>
                                            <MenuItem value="positivity">Positivity</MenuItem>
                                            <MenuItem value="surprise">Surprise</MenuItem>
                                            <MenuItem value="sadness">Sadness</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                                        <InputLabel sx={{ color: 'rgba(255,255,255,0.7)' }}>Speed</InputLabel>
                                        <Select
                                            value={editingPace}
                                            onChange={(e) => setEditingPace(e.target.value)}
                                            label="Speed"
                                            sx={{
                                                color: 'white',
                                                fontSize: '0.8rem',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(255,255,255,0.23)',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: PRIMARY_COLOR,
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: PRIMARY_COLOR,
                                                },
                                            }}
                                        >
                                            <MenuItem value="normal">Normal</MenuItem>
                                            <MenuItem value="fast">Fast</MenuItem>
                                            <MenuItem value="slow">Slow</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        )}
                    </Box>
                ))}


            </Box>

            <Box sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
                <Button
                    startIcon={<AddIcon />}
                    onClick={handleAddNewSegment}
                    variant="outlined"
                    sx={{
                        borderColor: 'rgba(255,255,255,0.23)',
                        color: 'rgba(255,255,255,0.7)',
                        '&:hover': {
                            borderColor: PRIMARY_COLOR,
                            color: PRIMARY_COLOR,
                        },
                    }}
                >
                    Add New Segment
                </Button>
            </Box>
        </Box>
    );
}

function formatTimeDisplaySimple(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export default TranscriptViewer;